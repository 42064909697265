import Footer from "./components/footer/footer";
import Profile from "./components/profile/profile";
import "./styles/app.scss";

const App = () => {
  return (
    <div className="app">
      <Profile />
      <Footer />
    </div>
  );
};

export default App;
